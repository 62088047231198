import React from "react";
import PropTypes from "prop-types";
import "./team.css";
import ModalImage from "react-modal-image";

export default function Team({ score, thumb, watermark }) {
  return (
    <div className="team">
      <p className="score">{score}</p>
      <ModalImage className="photo" small={thumb} large={watermark} alt="" />
    </div>
  );
}

Team.propTypes = {
  score: PropTypes.string,
  thumb: PropTypes.string,
  watermark: PropTypes.string,
};
