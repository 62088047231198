import React from "react";
import PropTypes from "prop-types";
import "./header.css";

export default function Header(props) {
  return (
    <header>
      <h1 className="header--title"> Waar sta ik in de Top 2000?</h1>
      <div className="header--searchbar">
        <label htmlFor="search">Zoek op plaats in de Top 2000</label>
        <input
          onChange={props.handleChange}
          value={props.search}
          type="text"
          id="search"
          name="search"
          size="1"
          maxLength="4"
          placeholder="1234"
          autoComplete="off"
        ></input>
      </div>
    </header>
  );
}

Header.propTypes = {
  search: PropTypes.string,
  handleChange: PropTypes.func,
};
