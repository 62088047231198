import React from "react";
import "./styles.css";
import Team from "./components/Team";
import Header from "./components/Header";

export default function App() {
  const [thumbs, setThumbs] = React.useState([]);
  const [visible, setVisible] = React.useState(10);
  const [search, setSearch] = React.useState("");

  const fetchAndStore = () => {
    const regExp = /(?<=thumb-)\d{1,4}/;
    fetch(`${process.env.REACT_APP_SERVER}/thumbs`)
      .then((response) => response.json())
      .then((data) => {
        const newData = data
          .map((thumb) => {
            return {
              score: thumb.match(regExp)[0],
              thumb: thumb,
              watermark: thumb.replace("thumb-", "le-"),
            };
          })
          .sort((a, b) => a.score - b.score);
        setThumbs(newData);
      });
  };

  React.useEffect(() => {
    fetchAndStore();
  }, []);

  React.useEffect(() => {
    if (search === "") {
      setVisible(10);
    } else {
      setVisible(thumbs.length);
    }
  }, [search, thumbs.length]);

  let teams = thumbs.slice(0, visible);
  if (search !== "") {
    teams = teams.filter((team) => team.score.includes(search));
  }
  const allTeams = teams.map((thumb) => {
    return (
      <Team
        key={thumb.thumb}
        score={thumb.score}
        thumb={`${process.env.REACT_APP_SERVER}/${thumb.thumb}`}
        watermark={`${process.env.REACT_APP_SERVER}/${thumb.watermark}`}
      />
    );
  });

  const loadMore = () => {
    setVisible((prev) => {
      return prev + 10;
    });
  };

  const searchFor = (event) => {
    setSearch(event.target.value);
  };

  return (
    <div>
      <Header search={search} handleChange={searchFor} />
      {[allTeams]}
      <button
        onClick={loadMore}
        name="load-more"
        id="load-more"
        className="load-more"
      >
        Toon meer
      </button>
    </div>
  );
}
